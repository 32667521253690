<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    VclList,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      body: null,
      loading: true,
      errored: false,
      empty: false,

      ticket: {
        modal: false,
        loading: false,
        alert: {
          type: '',
          message: ''
        },
        subject: '',
        description: '',
      }
    };
  },
  validations: {
    ticket: {
      subject: { required },
      description: { required, minLength: minLength(2) }
    },
  },
  methods: {
    getTickets() {
      api
        .get('suporte')
        .then(res => {
          if (res.data.status == 'success') {
            this.body = res.data.list
          } else {
            this.body = null;
          }
        })
        .catch(error => {
          this.errored = error
        })
        .finally(() => {
          this.loading = false
          if (this.body == '' || this.body == null) {
            this.empty = true
          }
        })
    },
    ticketSubmit() {
      this.$v.ticket.$touch();

      if (!this.$v.ticket.$error && this.ticket.subject && this.ticket.description) {
        this.ticket.loading = true

        api
          .post('suporte', {
            subject: this.ticket.subject,
            description: this.ticket.description
          })
          .then(res => {
            if (res.data.status == 'success') {
              this.ticket.subject = ''
              this.ticket.description = ''
              this.$v.ticket.$reset()

              this.ticket.alert.type = 'alert-success'
              this.ticket.alert.message = 'O ticket foi criado, aguarde o contato.'

              this.ticket.modal = false
              this.$router.push('/suporte/' + res.data.ticket.id)
            }
          })
          .catch(error => {
            this.ticket.errored = error

            this.ticket.alert.type = 'alert-danger'
            this.ticket.alert.message = 'O ticket não pôde ser criado, tente novamente.'

            this.ticket.loading = false
          })
          .finally(() => {
          })
      }
    },
  },
  mounted() {
    this.getTickets()
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Suporte</h3>
        <p></p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="ticket.modal = true">Criar ticket</b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="errored">Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.</div>
            <div v-else-if="empty">Nenhum registro encontrado.</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>#</th>
                    <th>Assunto</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in body" :key="index">
                    <td>
                      <img v-if="account.user.avatar" class="rounded-circle header-profile-user" :src="account.user.avatar" alt="" />
                      <img v-else class="rounded-circle header-profile-user ml-2" :src="'https://ui-avatars.com/api/?background=0C4CBC&color=fff&name=' + account.user.name" alt="" />
                    </td>
                    <td class="notranslate">{{ td.id }}</td>
                    <td class="notranslate">{{ td.subject }}</td>
                    <td class="notranslate"><i class="mdi mdi-clock-outline font-size-18 align-middle"></i> {{ td.date }}</td>
                    <td>{{ td.status }}</td>
                    <td class="text-right">
                      <router-link :to="'/suporte/' + td.id" class="btn btn-default btn-sm btn-rounded">
                        Ver
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="ticket.modal" modal-class="modal-right" hide-header hide-footer>
      <b-form @submit.prevent="ticketSubmit">
        <div v-if="ticket.alert.message" :class="'alert ' + ticket.alert.type">{{ ticket.alert.message }}</div>
        <b-form-group id="ticket-subject" label="Assunto" label-for="ticket-subject">
          <b-form-input id="ticket-subject" v-model="ticket.subject" type="text" :class="{ 'is-invalid': $v.ticket.subject.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.ticket.subject.$error" class="invalid-feedback">
            <span v-if="!$v.ticket.subject.required">O assunto é obrigatório</span>
          </div>
        </b-form-group>
        <b-form-group id="ticket-description" label="Mensagem" label-for="ticket-description">
          <b-form-textarea id="ticket-description" v-model="ticket.description" rows="5" max-rows="6" :class="{ 'is-invalid': $v.ticket.description.$error }"></b-form-textarea>
          <div v-if="$v.ticket.description.$error" class="invalid-feedback">
            <span v-if="!$v.ticket.description.required || !$v.ticket.description.minLength">A mensagem é obrigatória</span>
          </div>
        </b-form-group>

        <div class="d-flex mt-3">
          <b-button :disabled="ticket.loading == true || this.ticket.subject.length < 2 || this.ticket.description.length < 2 || $v.ticket.$error" type="submit" variant="default">
            Enviar
            <b-spinner v-if="ticket.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </b-button>
          <a class="btn btn-outline-link" v-on:click="$v.ticket.$reset(); ticket.modal = false;">Fechar <sup>ESC</sup></a>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>
